import { useRef, useState } from 'react'
import headerLogo from '@/assets/images/vector/logo.svg'
import arrowConnect from '@/assets/images/vector/connext.svg'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin.js'
import { ScrollSmoother } from 'gsap/ScrollSmoother.js'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'
import { useGSAP } from '@gsap/react'

gsap.registerPlugin(useGSAP, ScrollTrigger, ScrollToPlugin, ScrollSmoother)
import './Header.scss'
import logo from '@/assets/images/vector/logo.svg'
import { isMobile } from 'react-device-detect'
import socGit from '@/assets/images/vector/soc-git.svg'
import socTelegram from '@/assets/images/vector/soc-tg.svg'
import socX from '@/assets/images/vector/soc-x.svg'
import socDiscord from '@/assets/images/vector/soc-discord.svg'
import socGitbook from '@/assets/images/vector/soc-git.svg'
import socMedium from '@/assets/images/vector/soc-medium.svg'


function Header() {
	const [openedClass, setOpenedClass] = useState(0)
	const menuElem = useRef()
	const menuBtn = useRef()
	let isMenuOpened = false

	function handleMenuBtnClick(e) {
		isMenuOpened = !isMenuOpened
		if (isMenuOpened) {
			menuElem.current.classList.add('opened')
			menuBtn.current.classList.add('actived')
			document.body.classList.add('menu-opened')
		} else {
			menuElem.current.classList.remove('opened')
			menuBtn.current.classList.remove('actived')
			document.body.classList.remove('menu-opened')
		}
		console.log('You clicked submit.' + isMenuOpened)
	}

	function handleCloseMenuBtn(e) {
		isMenuOpened = false
		menuElem.current.classList.remove('opened')
		menuBtn.current.classList.remove('actived')
		document.body.classList.remove('menu-opened')

	}

	function handleMenuLinkClick(elemname) {
		let previouslyCreatedSmoother = ScrollSmoother.get()
		previouslyCreatedSmoother.scrollTo(elemname, true)
		gsap.to(previouslyCreatedSmoother, {
			// don't let it go beyond the maximum scrollable area
			scrollTop: previouslyCreatedSmoother.offset(elemname, 0),
			duration: 1
		})
		handleCloseMenuBtn()

	}

	return (
		<>
			<header>
				<div className="container">
					<div className="header-row">
						<div className="left-part">
							<div className="logo-box">
								<span>ganntec</span>
								<i>
									<img src={logo} alt="" />
								</i>
							</div>
						</div>
						<div className="right-part">
							<a href="https://app.ganntec.com" className="connect-btn">
								<span>Launch app</span>
								<i>
									<img src={arrowConnect} alt="" />
								</i>
							</a>
							<button className="menu-btn" type="button" ref={menuBtn} onClick={handleMenuBtnClick}>
								<span></span>
							</button>
						</div>
					</div>
				</div>
			</header>
			<div className="site-menu" ref={menuElem}>
				<button className="close-btn" type="buton" onClick={handleCloseMenuBtn}></button>
				<div className="holder">
					<div className="menu-center">
						<ul className="footer-nav">
							<li className="home">
								<a href="#home" onClick={() => {
									handleMenuLinkClick('.first-screen-section')
								}}>Home</a>
							</li>
							<li>
								<a href="#" onClick={() => {
									handleMenuLinkClick('.features-section')
								}}>Overview</a>
							</li>
							<li>
								<a href="#" onClick={() => {
									handleMenuLinkClick('.showcase-section')
								}}>Showcase</a>
							</li>
							<li>
								<a href="#" onClick={() => {
									handleMenuLinkClick('.overview-section')
								}}>Features</a>
							</li>
							<li>
								<a href="#" onClick={() => {
									handleMenuLinkClick('.levels-section')
								}}>Plans</a>
							</li>
							<li>
								<a href="#" onClick={() => {
									handleMenuLinkClick('.referrals-section')
								}}>Referrals</a>
							</li>
							<li>
								<a href="https://apply.workable.com/ganntec/" target="_blank">Careers</a>
							</li>
						</ul>
					</div>
					<div className="menu-bottom">
						<div className="soc-links">
							<span className="soc-name">Socials</span>
							<ul className="soc-list">
								<li><a target="_blank" href="https://t.me/GanntecPortal"><img src={socTelegram} alt="" /></a></li>
								<li><a target="_blank" href="https://x.com/Ganntec_"><img src={socX} alt="" /></a></li>
								<li><a target="_blank" href="https://discord.gg/Rb3zKa2Unb"><img src={socDiscord} alt="" /></a></li>
								<li><a target="_blank" href="https://docs.ganntec.com/"><img src={socGitbook} alt="" /></a></li>
								<li><a target="_blank" href="https://medium.com/@ganntec"><img src={socMedium} alt="" /></a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Header
